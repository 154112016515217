import { Types } from 'common';

type GetSearchQueryProps = {
    pageNumber: Types.SearchQuery['pageNumber'];
    pageSize: Types.SearchQuery['pageSize'];
    filters?: Types.SearchQuery['filters'];
    orders?: Types.SearchQuery['orders'];
    query?: Types.SearchQuery['query'];
};

const getSearchQuery = ({
    pageNumber,
    pageSize,
    filters = [],
    orders = [],
    query = ''
}: GetSearchQueryProps): { searchQuery: Types.SearchQuery } => ({
    searchQuery: {
        pageNumber,
        pageSize,
        filters,
        aggregations: [],
        orders,
        query
    }
});

export default getSearchQuery;
