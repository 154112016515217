import React, { memo } from 'react';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import { Typography } from 'components';
import { formatters, ColoredIcon } from 'common';

import type { TopicItemProps } from './TopicItem.types';
import useStyles from './TopicItem.styles';

const xsSize = 36;
const mdSize = 80;

const TopicItem = (props: TopicItemProps) => {
    const { topic, onClick, isActive, variant, showIcon = true, isDropdown } = props;
    const classes = useStyles();
    const { locale } = useIntl();

    const isHorizontalList = variant === 'horizontal-list';

    const handleClick = () => {
        if (onClick) {
            onClick(topic);
        }
    };

    return (
        <Box
            className={classnames(classes.container, {
                [classes.selectable]: typeof onClick !== 'undefined',
                [classes.active]: isActive,
                [classes.horizontalList]: isHorizontalList
            })}
            onClick={handleClick}
        >
            {topic.specialityIconUrl && (topic.level ?? 3) < 2 && showIcon && (
                <ColoredIcon
                    url={topic.specialityIconUrl}
                    color={topic.iconPrimaryColor}
                    width={isDropdown ? xsSize : mdSize}
                    height={isDropdown ? xsSize : mdSize}
                    className={classnames(classes.image, {
                        [classes.horizontalList]: isHorizontalList
                    })}
                />
            )}
            <Typography
                className={classnames(classes.title, {
                    [classes.horizontalList]: isHorizontalList
                })}
                title={formatters.formatTranslation(topic.description, { locale })}
            />
        </Box>
    );
};

export default memo(TopicItem);
