import React from 'react';
import MobileFilters from './Filters.mobile';
import DesktopFilters from './Filters.desktop';
import { useIsDesktop } from 'common';
import { FilterProps } from './Filters.types';

const Filters = (props: FilterProps) => {
    const isDesktop = useIsDesktop();

    if (isDesktop) {
        return <DesktopFilters {...props} />;
    }
    return <MobileFilters {...props} />;
};

export default Filters;
