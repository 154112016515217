import { Types } from 'common';
import getSearchQuery from '../../utils/queries/getSearchQuery';
import { ContentContentTypeFilter, SortingProductEnum } from '../../components/Filters';
import { CONTENT_TYPE_QUERY_PARAM_NAME } from '../../components/Filters/Filters.types';
import { DateFilterOptionEnum } from '../../utils/filters/dateFilter';

type SearchQuery = Types.SearchQuery;

export const TOPICS_PER_PAGE = 7;
export const COURSES_PER_PAGE = 5;
export const WEBUPS_PER_PAGE = 4;
export const LECTURES_PER_PAGE = 5;

/**
 * Filter contents without rating and contents with rating greater or equal than 4.3 over 5.
 */
export const ratingThresholdFilter: Types.SearchFilter = {
    field: 'rating',
    value: ['4.3'],
    operation: Types.SearchFilterOperation.GreaterThanEqual,
    valueWithNull: true
};

export const getContentTypeDateQueryParams = (
    contentType: ContentContentTypeFilter,
    date?: DateFilterOptionEnum
) => {
    return `${CONTENT_TYPE_QUERY_PARAM_NAME}=${contentType}${date ? `&date=${date}` : ''}&sortBy=${SortingProductEnum.RELEVANCE}`;
};

type GetTopicsSearchQueryProps = {
    pageNumber?: SearchQuery['pageNumber'];
    pageSize?: SearchQuery['pageSize'];
    filters?: SearchQuery['filters'];
    levels?: Array<string>;
    parentCode?: string | null;
    orders?: SearchQuery['orders'];
};

export const getTopicsSearchQuery = ({
    pageNumber,
    pageSize,
    filters,
    levels,
    parentCode,
    orders = []
}: GetTopicsSearchQueryProps): { searchQuery: SearchQuery } => {
    return getSearchQuery({
        pageNumber,
        pageSize,
        filters: [
            ...(filters ?? []),
            ...(getTopicLevelFilters(levels) ?? []),
            ...(getTopicParentFilters(parentCode) ?? []),
            ...[
                {
                    field: 'activated',
                    operation: Types.SearchFilterOperation.Equal,
                    value: ['true']
                }
            ]
        ],
        orders
    });
};

const getTopicLevelFilters = (levels?: Array<string>): SearchQuery['filters'] => {
    if (!levels || levels.length < 1) {
        return null;
    }
    return [{ field: 'level', operation: Types.SearchFilterOperation.Equal, value: levels }];
};

const getTopicParentFilters = (parentCode?: string | null): SearchQuery['filters'] => {
    if (!parentCode) {
        return null;
    }
    return [
        { field: 'parentCode', operation: Types.SearchFilterOperation.Equal, value: [parentCode] }
    ];
};
