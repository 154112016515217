import React from 'react';
import { useIntl } from 'react-intl';
import { Dropdown, SectionsMenu, Icon } from 'components';
import classNames from 'classnames';
import { Box, Container, Grid } from '@mui/material';
import { formatters, Types, isFeatureEnabled, getCountryCode } from 'common';
import TopicsHeaderDropdown from '../TopicsHeader/TopicsHeader.dropdown';
import {
    SortingProductEnum,
    getContentTypeSections,
    sortingProductMenuOptions,
    lectureSourceFilterMenuSections,
    FilterProps
} from './Filters.types';
import { dateFilterMenuOptions, getIsDateFilterVisible } from '../../utils/filters/dateFilter';
import FilterUtils from './Filters.utils';
import useStyles from './Filters.styles';

const DesktopFilters = (props: FilterProps) => {
    const { allowContentTypeFilter = true, onChange, countByLanguage } = props;
    const { messages, formatMessage, locale } = useIntl();
    const classes = useStyles();
    const countryCode = getCountryCode(locale);
    const languageDropdownOptions = FilterUtils.getDomainLanguageOptions(countryCode);
    const filteredLanguageDropdownOptions = countByLanguage
        ? FilterUtils.filterAvailableLanguageOptions(languageDropdownOptions, countByLanguage)
        : languageDropdownOptions;
    const isLanguageFilterAvailable =
        isFeatureEnabled('contentLanguageSwitch', countryCode) &&
        filteredLanguageDropdownOptions.length > 0;

    const {
        queryParams,
        handleContentTypeChange,
        handleDateChange,
        handleSourceChange,
        handleSortingChange,
        handleTopicChange,
        handleChangeLanguage
    } = FilterUtils.useFilters(onChange);

    const {
        contentTypeFilter = 'ALL',
        date,
        sortBy = SortingProductEnum.RECENT,
        lectureSource = null,
        language
    } = queryParams;

    const contentTypeOption = contentTypeFilter !== 'ALL' ? contentTypeFilter : null;
    const lectureSourceOption = lectureSource !== 'ALL' ? lectureSource : null;

    const isDateFilterVisible = getIsDateFilterVisible(contentTypeFilter);

    return (
        <Box>
            <Container maxWidth="xl" className={classes.header}>
                <Grid container className={classes.filtersAndSortingHeader}>
                    <TopicsHeaderDropdown
                        onChange={handleTopicChange}
                        variant="multi-dropdown"
                        className={classNames(classes.filter, classes.topicDropdown, {
                            [classes.topicWideDropdown]: locale === 'fr-CH'
                        })}
                    />
                    {allowContentTypeFilter && (
                        <>
                            <Dropdown
                                titleLocaleId="catalog.search-media.content-type"
                                value={contentTypeOption}
                                onChange={handleContentTypeChange}
                                options={getContentTypeSections(locale)}
                                className={classes.filter}
                            />
                            {contentTypeFilter === Types.ContentType.Lecture ? (
                                <Dropdown
                                    titleLocaleId="catalog.search-media.source"
                                    value={lectureSourceOption}
                                    onChange={handleSourceChange}
                                    options={lectureSourceFilterMenuSections}
                                    className={classes.filter}
                                />
                            ) : (
                                isDateFilterVisible && (
                                    <SectionsMenu
                                        menus={dateFilterMenuOptions}
                                        onChange={handleDateChange}
                                        selection={date || ''}
                                        className={classes.filter}
                                        classes={{
                                            menus: classes.sectionsMenuContainer,
                                            menu: classes.sectionsMenuItem
                                        }}
                                    />
                                )
                            )}
                        </>
                    )}
                    {isLanguageFilterAvailable && (
                        <Dropdown
                            className={classes.languageDropdown}
                            options={filteredLanguageDropdownOptions}
                            value={language}
                            title={
                                <>
                                    <Icon
                                        className={classes.languageDropdownIcon}
                                        icon="language"
                                    />
                                    {formatMessage({ id: 'filters.language.title' })}
                                </>
                            }
                            onChange={handleChangeLanguage}
                            startIcon={
                                <Icon className={classes.languageDropdownIcon} icon="language" />
                            }
                        />
                    )}
                    <Dropdown
                        minimal
                        titleLocaleId="catalog.search-media.sorting"
                        titleLocaleValues={{
                            sorting: formatters.formatEnum(sortBy, {
                                messages,
                                options: sortingProductMenuOptions
                            })
                        }}
                        value={sortBy}
                        onChange={handleSortingChange}
                        options={sortingProductMenuOptions}
                        className={classes.filter}
                    />
                </Grid>
            </Container>
        </Box>
    );
};

export default DesktopFilters;
