import { useEffect, useState } from 'react';
import { useRouter, Fragments, Router, booleanFilter } from 'common';
import { useSearchForTopicsLazyQuery } from '../graphql/catalog/queries';
import { getTopicsSearchQuery } from '../containers/MediathekHome/MediathekHome.utils';

// we have 4 levels of topics, 0, 1, 2, 3
// 0 is very general like AM, IM
// 1 like KARDI, ONKO
// and it will go deeper in the tree by each level

// this is the selected topic in the default home page of Lernportal
// when no topic is selected for guest user
// this has no actual effect on page just a visual sign for not logged in
// users so they know the courses, etc. are sorted based on which topic
export const ACTIVE_TOPIC_CODE = 'AM';

export const TOPICS_QUERY_PARAM_NAME = 'topics';

type QueryParams = {
    [TOPICS_QUERY_PARAM_NAME]?: string | null;
    topic?: string | null;
    code?: string | null;
};

export type TopicBreadcrumbItem = Pick<Fragments.TopicFieldsFragment, 'id' | 'code'>;

const getSelectedTopicCode = (router: Router<QueryParams>) => {
    const {
        query: { topics: topicCode = null, topic: topicCodeHomePage },
        routeDynamicParams
    } = router;
    return routeDynamicParams.code ?? topicCode ?? topicCodeHomePage;
};

/**
 * @remarks
 * this hook should be used whenever we have a topic param in the url
 * or a router dynamic param for topics
 * this will manage the topics
 * @param shouldRedirectForSubtopics will redirect the user to search page on selecting the topics with level 2 or more
 */
export const useTopicParam = () => {
    const router = useRouter<QueryParams>({
        persistQueryInContext: false
    });

    // this will be the list of actual topics
    // it will be fetched considering the topic codes in the url
    const [topicsData, setTopicsData] = useState<Array<Fragments.TopicFieldsFragment>>([]);
    const [loading, setLoading] = useState(true);
    const topicCodes = getSelectedTopicCode(router);
    const decodedTopicCode = topicCodes ? decodeURIComponent(topicCodes) : null;
    const topics = decodedTopicCode?.split(',') || [];

    const [fetchTopics] = useSearchForTopicsLazyQuery();
    useEffect(() => {
        setLoading(true);
        const loadTopics = async () => {
            if (topics.length > 0) {
                const { data } = await fetchTopics({
                    variables: getTopicsSearchQuery({
                        pageNumber: 0,
                        pageSize: null,
                        filters: [{ field: 'code', value: topics }]
                    })
                });
                if (data?.topicDocuments.data) {
                    const loadedData = data?.topicDocuments.data;
                    setTopicsData(
                        topics
                            .map((each) => loadedData.find((e) => e.code === each))
                            .filter(booleanFilter)
                    );
                }
            } else {
                setTopicsData([]);
            }
            setLoading(false);
        };
        loadTopics();
    }, [topicCodes]);

    const lastTopicCode = topics.length > 0 ? topics[topics.length - 1] : null;

    const getNewTopicsToRedirect = (
        topicCode: Fragments.TopicFieldsFragment['code'] | undefined,
        index?: number
    ): Array<string> | null => {
        let newTopics = topics;
        // this is needed for breadcrumbs to select previous items in the list
        if (topicCode && topics.includes(topicCode) && typeof index !== 'number') {
            // find the index of selected item
            const topicsIndex = topics.findIndex((each) => each === topicCode);
            // remove items after the selected item
            newTopics = topics.slice(0, topicsIndex + 1);
        } else if (!topicCode && typeof index === 'number') {
            // deselect a topic in a dropdown
            newTopics = topics.slice(0, index);
        } else {
            if (topicCode) {
                // for multi dropdown topics, to indicate which dropdown has been selected
                if (typeof index === 'number') {
                    newTopics[index] = topicCode;
                    newTopics = topics.slice(0, index + 1);
                } else {
                    newTopics.push(topicCode);
                }
            }
        }
        return newTopics;
    };

    const updateTopicParam = (
        newTopicData: TopicBreadcrumbItem | Array<TopicBreadcrumbItem> | null,
        options?: { index?: number }
    ) => {
        const { index } = options ?? {};
        const { query, setQuery } = router;
        let newParam: string | null = null;
        if (Array.isArray(newTopicData)) {
            newParam =
                newTopicData.length === 0
                    ? ''
                    : encodeURIComponent(newTopicData.map((each) => each.code).join(','));
        } else {
            const combinedTopics = getNewTopicsToRedirect(newTopicData?.code, index);
            if (combinedTopics?.length) {
                newParam = encodeURIComponent(combinedTopics.join(','));
            } else {
                newParam = '';
            }
        }

        setQuery({ ...query, topics: newParam });
    };

    return {
        topics: topicsData,
        loading,
        lastTopicCode,
        getNewTopicsToRedirect,
        updateTopicParam
    };
};
